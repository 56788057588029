import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/app/src/components/layout/index.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const GridList = makeShortcode("GridList");
const GridListItem = makeShortcode("GridListItem");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <GridList columns="2" mdxType="GridList">
  <GridListItem title="Content foundations" titleElement="h3" linkText="Read the content foundations" linkTo="/content/content-foundations" border icon="writing-principles" mdxType="GridListItem">
    How to create content that's clear, scannable and inclusive.
  </GridListItem>
  <GridListItem title="Writing in experiences" titleElement="h3" linkText="Go to writing in experiences" linkTo="/content/writing-in-experiences" border icon="error-messages" mdxType="GridListItem">
    How to write the common messages you'll come across in experiences.
  </GridListItem>
  <GridListItem title="Writing for our brands" titleElement="h3" linkText="Go to writing for our brands" linkTo="/content/writing-for-our-brands" border icon="writing-for-colleagues" mdxType="GridListItem">
    How to write for digital experiences whilst staying true to the voices of
    our brands.
  </GridListItem>
  <GridListItem title="Style guide" titleElement="h3" linkText="Go to style guide" linkTo="/content/style-guide" border icon="style-guide" mdxType="GridListItem">
    A guide covering grammar and style conventions for digital experiences.
  </GridListItem>
    </GridList>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      